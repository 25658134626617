$c-primary: #58003a;
$c-secandary: #58003a;
$dots: false;
$version: 3.1;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'ProximaNova', sans-serif;
$font-family-heading: 'ProximaNova', sans-serif;

      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-Bold.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-BoldItalic.otf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  .error {
    font-size: 12px;
    color: red;
    padding: 7px 15px;
  }
}
