$c-primary: #58003a;
$c-secandary: #58003a;
$dots: false;
$version: 3.1;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'ProximaNova', sans-serif;
$font-family-heading: 'ProximaNova', sans-serif;

      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-Bold.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-BoldItalic.otf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  height: 100%;

  .buttons {
    display: flex;
    justify-content: space-between;
    height: 100%;

    button {
      width: auto;
      height: 100%;
      min-width: 57%;

      &:first-child {
        text-align: left;
        min-width: 0px !important;
      }
    }
  }

  .btnFull {
    width: 100%;
    height: 100%;

    button {
      width: 100%;
      height: 100%;
    }
  }
}
