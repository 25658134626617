$c-primary: #58003a;
$c-secandary: #58003a;
$dots: false;
$version: 3.1;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'ProximaNova', sans-serif;
$font-family-heading: 'ProximaNova', sans-serif;

      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-Bold.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-BoldItalic.otf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  padding-top: 100px;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;

  .footer {
    position: fixed;
    bottom: 0;
    background-color: $c-grey-light;
    padding: 0px 20px 20px;
    width: 100%;
    right: 0;
    height: 100px;
  }
}

.buttonsWrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 15px;

  .buttons {
    margin-left: -7px;
    margin-right: -7px;
    display: flex;
    justify-content: center;

    button {
      width: 100%;
      margin: 0 7px;
      max-width: 250px;
    }
  }
}

.review {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  .header {
    color: gray;
  }

  .questionsGroups {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-bottom: 100px;

    .questionsGroup {
      border-color: #f5f5f5;
      border-style: solid;
      border-radius: 5px;
      border-width: 1px;

      .questions {
        display: flex;
        flex-direction: column;
        padding: 5px;

        .question {
          .title {
          }

          .answer {
            color: gray;
          }
        }
      }

      .edit {
        padding: 10px;
        border-top-style: solid;
        border-top-width: 1px;
        border-radius: 5px;
        border-top-color: #f5f5f5;
        text-align: right;
      }
    }

    .questionsGroup:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .importantInfo {
    padding: 10px;
  }
}
