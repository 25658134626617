$c-primary: #58003a;
$c-secandary: #58003a;
$dots: false;
$version: 3.1;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'ProximaNova', sans-serif;
$font-family-heading: 'ProximaNova', sans-serif;

      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-Bold.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-BoldItalic.otf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.inputWrapper {
  position: relative;
  width: 100%;

  textarea {
    resize: none;
    height: 3.75rem; //60
    padding-top: 1rem;
  }

  .error {
    color: $c-danger;
    font-size: 0.8125rem; //13
  }

  input,
  textarea {
    width: 100%;
    height: 3.75rem; //60
    font-size: 1rem; //16
    line-height: 1.2;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 0.7rem 3rem 0.7rem 0.7rem;
    color: #394448;
    transition: 0.1s ease-out;
    font-family: 'Rubik', sans-serif;
    margin: 0;
    &:focus {
      border-color: black;
    }
  }
}

.leftPadding30Perc {
  input,
  textarea {
    padding: 0.5rem 0.7rem 0.5rem 35% !important;
  }
}

.search {
  &::after {
    background: url(_img('icons/png/search.png')) no-repeat 0 0;
  }
}
