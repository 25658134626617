$c-primary: #58003a;
$c-secandary: #58003a;
$dots: false;
$version: 3.1;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'ProximaNova', sans-serif;
$font-family-heading: 'ProximaNova', sans-serif;

      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-Bold.otf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-BoldItalic.otf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/fonts/ProximaNova-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  position: relative;

  .inputWrapper {
    position: relative;
    margin-bottom: 10px;
    .icon {
      position: absolute;
      right: 5px;
      top: 50%;
      margin-top: -23px;
    }

    .manual_cta {
      display: block;
      text-align: right;
      font-size: 14px;
      margin-bottom: 20px;
      a {
        text-decoration: none;
        color: var(--secondary);
      }
    }

    .inputElement {
      textarea,
      input {
        padding: 1rem 1.6rem 1rem 0.7rem;
      }
    }
  }

  .title {
    margin-bottom: 20px;
    font-size: 14px;
  }

  .input {
    margin-bottom: 30px;
    &.halfWith {
      max-width: 50%;
      margin-right: 15px !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .addresses {
    display: block;
    background: #fff;
    padding: 0;
    position: absolute;
    width: calc(100%);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0), 0 6px 6px rgba(0, 0, 0, 0.11);
    max-height: 220px;
    overflow: auto;
    margin-top: -20px;
    z-index: 2;

    li {
      display: block;
      padding: 18px 13px;
      border-bottom: 1px solid #f2f2f2;
      color: var(--primary);
      font-size: 14px;
      line-height: 18px;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .countries {
    display: block;
    background: #fff;
    border: 1px solid #ccc;
    padding: 0;
    position: absolute;
    width: calc(100% - 30px);
    box-shadow: -1px 1px 3px #eee;
    max-height: 200px;
    overflow: auto;
    margin-top: -15px;
    z-index: 2;

    li {
      display: block;
      padding: 13px;
      border-bottom: 1px solid #ddd;
      font-size: 12px;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .detailedWrapper {
    margin-bottom: 30px;
    .inputGroup {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      > * {
        flex: 0 0 49%;
      }
    }
  }
}
